<template>
  <vx-card title="Carga de Csd">
    <form method="POST">
      <div class="vx-row">
        <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-full w-full">
          <vs-input
            class="w-full mb-3"
            label="RFC"
            type="text" name="rfc" v-model="csdsForm.rfc" disabled></vs-input>
          <vs-button-file
            class="w-full mb-3"
            :accept-file-types="'application/x-x509-ca-cert'"
            label="Seleccionar certificado"
            @onChange="readFile($event, 'certificate')"></vs-button-file>
        </div>
        <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-full w-full">
          <vs-input
            class="w-full mb-3"
            label="Contraseña de llave privada"
            type="password" name="private_key_password" v-model="csdsForm.privateKeyPassword"></vs-input>
          <vs-button-file
              class="w-full mb-3"
              :accept-file-types="'.key'"
              label="Seleccionar llave privada"
              @onChange="readFile($event, 'privateKey')"></vs-button-file>
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
          <p class="mb-4 text-justify">
            El usuario se atiene a los <a
              href="https://app.facturama.mx/Content/docs/facturama-terminos-y-condiciones-del-servicio.pdf">términos y
            condiciones</a> de EXPRESIÓN EN SOFTWARE, S.A.P.I. DE C.V. (conocida comercialmente como
            FACTURAMA).
            Red Girasol, S.A.P.I. de C.V. no asume responsabilidad alguna por los servicios prestados por terceros,
            incluyendo Facturama, ni por el manejo de los datos proporcionados a dichos terceros. El usuario reconoce que
            Red Girasol, S.A.P.I. de C.V. no está autorizado por el Servicio de Administración Tributaria para actuar como
            Proveedor Autorizado de Certificación (PAC), ni tampoco presta asesoría legal ni fiscal. Cada usuario deberá
            consultar a sus asesores respecto a la manera correcta de emitir comprobantes fiscales digitales por Internet
            que, en su caso, correspondan a las actividades realizadas o ingresos percibidos por cada contribuyente.
          </p>
        </div>
      </div>
      <vs-button @click="uploadCsds">
        Subir
      </vs-button>
    </form>
  </vx-card>
</template>

<script>
import VxUpload from "../../../components/upload/VxUpload";
import VsButtonFile from "../../../components/upload/VsButtonFile";

export default {
  name: "UploadCsds",
  components: {VsButtonFile, VxUpload},
  data: () => ({
    csdsForm: {
      rfc: "",
      certificate: null,
      privateKey: null,
      privateKeyPassword: null
    }
  }),
  props: {
    applicant: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    csdId: {
      type: Number
    }
  },
  mounted() {
    this.csdsForm.rfc = this.applicant.rfc;
  },
  methods: {
    readFile(file, type) {
      this.csdsForm[type] = file
    },
    async uploadCsds() {
      if (this.editMode === true) {
        await this.deleteCsd();
      }
      let form = new FormData();
      form.append("rfc", this.csdsForm.rfc);
      form.append("certificate", this.csdsForm.certificate);
      form.append("private_key", this.csdsForm.privateKey);
      form.append("private_key_password", this.csdsForm.privateKeyPassword);

      this.$vs.loading();
      try {
        const {data} = await axios.post(
            `/api/billing/client/${this.applicant.id}/csds`,
            form,
            { "Content-Type": "multipart/form-data" }
        )
        let response = data;
        this.successNotifDialog(
            "Notificación",
            "El certificado CSD se subió correctamente, espere unos minutos para validar si su CSD fue validado."
        )
        this.$emit("onUploadedSuccess", response)
      } catch (e) {
        if (e.response.status === 500) {
          this.warningNotifDialog("Error", "Hubo un error en la comunicación con el PAC.")
        } else {
          this.warningNotifDialog("Error", e.response.data.error)
          this.$emit("onUploadedFailed", null)
        }
      } finally {
        this.$vs.loading.close();
      }
    },
    async deleteCsd() {
      this.$vs.loading();
      try {
        await axios.delete(`/api/billing/client/${this.applicant.id}/csds/${this.csdId}`)
        this.applicant.csds = null;
      } catch (e) {
        if (e.response.status === 422) {
          this.warningNotifDialog("Error", e.response.data.error)
        }
      } finally {
        this.$vs.loading.close();
      }
    }
  }
}
</script>

<style scoped>
.vs-input {
  width: 350px;
}
</style>
