<template>
  <vx-card title="Certificados CsDs.">
    <vs-table :data="[{}]">
      <template slot="thead">
        <vs-th>RFC</vs-th>
        <vs-th>FECHA DE GENERACIÓN</vs-th>
        <vs-th>FECHA DE EXPIRACIÓN</vs-th>
        <vs-th>ESTADO</vs-th>
        <vs-th>COMENTARIOS</vs-th>
        <vs-th>ACCIONES</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr>
          <vs-td>{{csds.rfc}}</vs-td>
          <vs-td>{{csds.valid_from_date|formatDate}}</vs-td>
          <vs-td>
            <span>
              {{csds.expiry_date|formatDate}}
              <template v-if="isExpired(csds.expiry_date)">
                <br>
                <small class="text-danger">
                  Certificado vencido
                </small>
              </template>
            </span>
          </vs-td>
          <vs-td>
            <span
                :class="{
                  'text-warning': csds.status === 'pending_validation',
                  'text-success': csds.status === 'validated',
                  'text-danger': csds.status === 'error',
                }">{{csds.status|toStr}}</span>
          </vs-td>
          <vs-td>{{csds.status_description === null ? 'Sin comentarios' : csds.status_description}}</vs-td>
          <vs-td>
            <vs-button
                :disabled="csds.status === 'validated' && !isExpired(csds.expiry_date)"
                @click="deleteCsd(csds)">
              Editar
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import moment from "moment";

export default {
  name: "CsdsList",
  props: {
    csds: {
      type: Object,
      required: true
    }
  },
  filters: {
    toStr(val) {
      if (val === 'pending_validation')
        return 'Pendiente de validación'
      if (val === 'validated')
        return 'Validado'
      if (val === 'error')
        return 'Error'
    },
    formatDate(val) {
      return moment(val).locale("es").format("DD MMMM [del] YYYY")
    }
  },
  methods: {
    isExpired(expiredDate) {
      let diffDays = moment().diff(moment(expiredDate), 'days', false);
      return diffDays > 0
    },
    deleteCsd(csd) {
      this.$emit("onDelete", csd)
    }
  }
}
</script>

<style scoped>

</style>
