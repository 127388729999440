<template>
  <div v-if="applicant">
    <upload-csds
        v-if="applicant.csds === null || editMode"
        :applicant="applicant"
        :editMode.sync="editMode"
        :csd-id="csdForDelete.id"
        @onUploadedSuccess="onUploadedSuccessHandler"></upload-csds>
    <csds-list
        v-else
        @onDelete="deleteCsdHandler"
        :csds="applicant.csds"></csds-list>

        <vx-card title="Perfil fiscal."
            card-background="dark"
            title-color="white"
            content-color="white"

            class="mt-6 mb-6">
            <div class="vx-col xxl:w-3/4 w-full">
                <p>
                    Debido a la Reforma Fiscal 2022, para la emisión de comprobante <b>(CFDI)</b> es importante que 
                    registres tus datos fiscales tal y como aparecen en tu <b>Constancia de Situación Fiscal</b> 
                    la cual puedes consultar en el 
                    <a class="inline-link-primary bold" target="_blank" rel="noopener" :href="$sanitizeUrl(satUrl)">SAT</a>.
                </p>
            </div>
        </vx-card>
        <vx-card>
            <user-tax-profile />
        </vx-card>
  </div>
</template>

<script>
import UploadCsds from "./UploadCsds";
import CsdsList from "./CsdsList";
import {mapState} from "vuex";
import UserTaxProfile from '@components/profile_shared/UserTaxProfile';
export default {
  name: "CsdsPage",
  components: {CsdsList, UploadCsds, UserTaxProfile},
  data: () => ({
    applicant: null,
    editMode: false,
    csdForDelete: { id: null }
  }),
  computed: {
    ...mapState("auth", ["user"])
  },
  async mounted() {
    const {data} = await axios.get(`/api/applicant/${this.user.client_id}`)
    this.applicant = data;

    if (!this.applicant.required_invoice) {
      this.$router.back();
    }
  },
  methods: {
    onUploadedSuccessHandler(ev) {
      this.applicant.csds = ev
      if (this.editMode === true) {
        this.editMode = false;
      }
    },
    async deleteCsdHandler(csd) {
      this.editMode = true
      this.csdForDelete = csd;
    }
  }
}
</script>

<style scoped>

</style>
