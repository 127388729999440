<template>
  <div>
    <vs-button class="w-full" @click="openFileDialogHandler()">
      <feather-icon icon="UploadCloudIcon" svgClasses="h-5 w-5 stroke-current" />
      {{label}}
    </vs-button>

  <div
      style="width: 400px;"
      class="ml-2"
      v-if="fileName">{{fileName}}</div>
  <input
      @change="setFile"
      style="display: none"
      ref="fileInput"
      :accept="acceptFileTypes"
      type="file" name="private_key">

  </div>
</template>

<script>
export default {
  name: "VsButtonFile",
  data: () => ({
    file: null,
    fileName: null
  }),
  props: {
    label: {
      type: String,
      required: true
    },
    acceptFileTypes: {
      type: String,
      default: '*/*'
    }
  },
  methods: {
    openFileDialogHandler() {
      this.$refs.fileInput.click()
    },
    setFile(ev) {
      this.fileName = ev.target.files[0].name;
      this.$emit("onChange", ev.target.files[0])
    }
  }
}
</script>

<style scoped>

</style>